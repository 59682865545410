import AlertUtils from './alertUtils'

export let funcs = {
  isImgEmpty (path) {
    if (path) {
      return path;
    }
    return '/img/noimage.png';
  },
  /** get data */
  getCheckApiResponse (response) {
    if (response === false) {
      // AlertUtils.alert('error', `พบข้อผิดพลาด`);
      return response;
    }
    console.log('get data ', response)
    return response;
  },
  /** swal success */
  receivedCheckApiResponse (response) {
    if (response === false) {
      // AlertUtils.alert('error', `พบข้อผิดพลาด`);
      return response;
    }
    console.log('swal success ', response)
    AlertUtils.alert('success', `บันทึกสำเร็จ`);
    return response;
  },
  toDecimalFormat(val){
    if(!val){
      return "";
    }
    return Number(val).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })
  }
}

