<template lang="">
  <div>
    <base-header class="pb-7 pt-md-6 bg-gradient-dark"></base-header>
    <div class="order-xl-1">
      <div class="card custom-card">
        <div class="card-header">
          <b-row align-v="center" slot="header" >
            <b-col cols="8">
              <h2 class="mb-0"> <i class="fas fa-pen"></i> แก้ไขข้อมูลลูกค้า </h2>
            </b-col>
          </b-row>
        </div>

        <div class="card-body bg-white">
          <validation-observer v-slot="{handleSubmit}" ref="formValidator">
            <b-form @submit.prevent="handleSubmit(editHandler)">
              <form-customer :item="item"/>
              <div class="float-right d-flex">
                <span><router-link :to="'/customer'" class="nav-link text-underline"> กลับ</router-link></span>
                <!-- <b-button variant="secondary" @click="onBackHandler()"><i class="fa fa-arrow-left"></i> กลับ</b-button> -->
                <b-button type="submit" variant="primary" class="style_btn" v-if="permission.actionData.flag_update"><i class="fa fa-check"></i> บันทึก</b-button>
              </div>
            </b-form>
          </validation-observer>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import formCustomer from './form.customer';
import * as scripts from '../../../util/scripts';
export default {
  name: 'customer-view-page-customer-edit',
  data () {
    return {
      permission:{
        appSlug: 'customer',
        actionData: {}
      },
      item: {
        id: '',
        name: '',
        contact: '',
        phone: '',
        line: '',
        email: '',
        billing_address: '',
        tax_id: '',
        address: '',
        created: '',
        updated: '',
        remark: '',
        credit_status: '',
        credit_line: '',
        customer_type: '',
        credit_status: '',
        credit_line: '',
        company_name: '',
        billing_phone: ''
      },
      funcs: scripts.funcs,
      id: '',
    }
  },
  methods: {
    async editHandler () {
      const result = await this.HttpServices.putData(`/ab_customer/${this.id}`, this.item);
      if(result&&result.status.code=="200"){
        this.AlertUtils.alertCallback('success', `บันทึกสำเร็จ`, ()=>{
          this.$router.push('/customer');
        });
      }else{
        this.AlertUtils.alert('warning', result.status.message);
      }
    },
    onBackHandler(){
      this.$router.push("/customer");
    },

    async getCustomer(){
      const result = await this.HttpServices.getData(`/ab_customer/${this.id}`);
      if(result&&result.status.code=="200"){
        this.item = result.data;
      }else{
        this.AlertUtils.alertCallback('warning', `ไม่พบข้อมูล Customer`, ()=>{
          this.$router.push('/customer');
        });
      }
    }
  },
  components: {
    formCustomer
  },
  async mounted() {
    await this.PermissionServices.viewPermission(this.permission.appSlug, this.$router);
    this.permission.actionData = await this.PermissionServices.getPermission(this.permission.appSlug);
    this.id = await this.SessionStorageUtils.getSession("page-customer");
    await this.getCustomer();
  }
}
</script>
<style lang="">

</style>
